import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import { StoreProvider } from './store';
import './App.css';

function App() {
  return (
    <BrowserRouter>
      <StoreProvider>
        <Routes />
      </StoreProvider>
    </BrowserRouter>
  );
}

export default App;
