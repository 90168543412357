/**
 * 网络请求配置
 */
import axios from 'axios';
import { notification } from 'antd';
import 'antd/es/notification/style/css';

//失败提示
const codeMessage = {
  0: '网络错误，请检查网络是否畅通。',
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  405: '请求方法不被允许。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

axios.defaults.timeout = 100000;
axios.defaults.baseURL = 'https://65.21.93.81:7001'
  // process.env.NODE_ENV === 'development' ? 'http://192.168.2.210:7001' : '/';

/**
 * http request 拦截器
 */
axios.interceptors.request.use(
  (config) => {
    config.data = JSON.stringify(config.data);
    // config.headers.Authorization = getLocal("token");
    const token = getLocal('token');
    config.withCredentials = true;
    config.headers = {
      'Content-Type': 'application/json',
      Authorization: token,
    };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * http response 拦截器
 */
axios.interceptors.response.use(
  (response) => {
    // if (response?.data?.errCode === 2) {
    //   console.log('过期');
    // }
    const status = response?.data?.status || 200;
    if (status !== 200 && status !== 204) {
      notification.error({
        message: `请求错误 ${response?.data?.status }: ${response.config.url}`,
        description: response.data.message,
      });
    }
    if (status === 401) {
      window.location.href = '/';
      localStorage.clear();
    }
    return response;
  },
  (error) => {
    const { skipErrorHandler } = error.config;

    // 忽略统一异常处理
    if (skipErrorHandler) {
      return Promise.reject(error);
    }

    // 异常统一处理
    const status = error.response?.status ?? '';
    const msg = error.response?.data?.msg ?? '';
    const message = msg || codeMessage[status] || error.message || '未知异常';
    const url = error.config.url ?? '';
    notification.error({
      message: `请求错误 ${status}: ${url}`,
      description: message,
    });
    console.log('请求出错：', error.message);
    if (status === 401) {
      window.location.href = '/';
      localStorage.clear();
    }
    return Promise.reject(error);
  }
);

/**
 * 封装get方法
 * @param url  请求url
 * @param params  请求参数
 * @returns {Promise}
 */
export function get(url, params = {}) {
  // return new Promise((resolve, reject) => {
  //   axios
  //     .get(url, {
  //       params: params,
  //     })
  //     .then((response) => {
  //       //  landing(url, params, response.data);
  //       resolve(response.data);
  //     })
  //     .catch((error) => {
  //       reject(error);
  //     });
  // });
  return axios.get(url, {
    params,
  });
}

/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data) {
  // return new Promise((resolve, reject) => {
  //   axios.post(url, data).then(
  //     (response) => {
  //       //关闭进度条
  //       resolve(response.data);
  //     },
  //     (err) => {
  //       reject(err);
  //     }
  //   ).catch((error) => {
  //     reject(error);
  //   });
  // });
  return axios.post(url, data);
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function patch(url, data = {}) {
  // return new Promise((resolve, reject) => {
  //   axios.patch(url, data).then(
  //     (response) => {
  //       resolve(response.data);
  //     },
  //     (err) => {
  //       msag(err);
  //       reject(err);
  //     }
  //   );
  // });
  return axios.patch(url, data);
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
  // return new Promise((resolve, reject) => {
  //   axios.put(url, data).then(
  //     (response) => {
  //       resolve(response.data);
  //     },
  //     (err) => {
  //       msag(err);
  //       reject(err);
  //     }
  //   );
  // });
  return axios.put(url, data);
}

export function downFile(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios
      .get(url, {
        responseType: 'blob',
      })
      .then((response) => {
        //  landing(url, params, response.data);
        let url = window.URL.createObjectURL(response.data);
        console.log(url);
        var a = document.createElement('a');
        document.body.appendChild(a);
        a.href = url;
        a.download = `${Math.random() * 1e17}.jpg`;
        a.click();
        window.URL.revokeObjectURL(url);
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

//统一接口处理，返回数据
export default function httpClient(fecth, url, param) {
  return new Promise((resolve, reject) => {
    switch (fecth) {
      case 'get':
        console.log('begin a get request,and url:', url);
        get(url, param)
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            console.log('get request GET failed.', error);
            reject(error);
          });
        break;
      case 'post':
        post(url, param)
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            console.log('get request POST failed.', error);
            reject(error);
          });
        break;
      case 'downFile':
        downFile(url, param)
          .then(function (response) {
            resolve(response.data);
          })
          .catch(function (error) {
            console.log('get request downFile failed.', error);
            reject(error);
          });
        break;
      default:
        break;
    }
  });
}

export const getLocal = (key) => {
  return localStorage.getItem(key);
};

//  /**
//   * 查看返回的数据
//   * @param url
//   * @param params
//   * @param data
//   */
//  function landing(url, params, data) {
//    if (data.code === -1) {
//    }
//  }
