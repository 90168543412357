import { useEffect, useState } from 'react';
import { Table, Tag, Button, notification } from 'antd';
import moment from 'moment';
import http from '@/utils/http';
import { useStateStore } from '@store';

import 'antd/es/table/style/css';
import 'antd/es/tag/style/css';
import 'antd/es/button/style/css';
import 'antd/es/notification/style/css';

function AdminOrder(params) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useStateStore();

  const refund = async (record) => {
    const resp = await http('post', '/refund', {id: record._id})
    if (resp.status === 200) {
      notification.open({
        message: '',
        description: record.order_num + '退款更改成功',
      });
      await fetchData()
    }
  }

  // table 定义
  const columns = [
    {
      title: '订单状态',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => {
        let color = 'default';
        if (record.status === 'paid') {
          color = 'green';
        }
        if (record.status === 'refund') {
          color = 'volcano';
        }
        return <Tag color={color}>{record.status}</Tag>;
      },
    },
    {
      title: '订单号',
      dataIndex: 'order_num',
      key: 'order_num',
    },
    {
      title: '时间',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (_, record) =>
        moment(record.timestamp).format('YYYY-MM-DD hh:mm:ss'),
    },
    {
      title: '金额',
      dataIndex: 'money',
      key: 'money',
    },
    {
      title: '可用下载次数',
      dataIndex: 'downloadCount',
      key: 'downloadCount',
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => {
        return <Button type="primary" danger onClick={() => {
          refund(record)
        }}>退款</Button>;
      },
    },
  ];

  const fetchData = async (user_id) => {
    setLoading(true);
    const resp = await http('get', '/getAllOrderList');
    setData(resp);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(user._id);
  }, [user._id]);

  return (
    <div>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data}
        rowKey="_id"
      />
    </div>
  );
}

export default AdminOrder;
