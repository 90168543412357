// import { Carousel } from 'antd';
import Header from '@components/Header';
import Footer from '@components/Footer';
import { Outlet } from 'react-router-dom';

function Index() {
  return (
    <div>
      <Header />
      {/* banner */}
      <Outlet />
      <Footer />
    </div>
  );
}

export default Index;
