import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  OrderedListOutlined,
  UserOutlined,
  MoneyCollectOutlined,
} from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import React, { useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import DashboardHeader from '@components/DashboardHeader';

import 'antd/es/layout/style/css';
import 'antd/es/menu/style/css';
import './index.css';

const { Header, Sider, Content } = Layout;

function DashBoard(params) {
  const [collapsed, setCollapsed] = useState(false);
  const navigate = useNavigate()

  return (
    <div className="h-screen">
      <DashboardHeader />
      <Layout>
        <Sider theme="light" trigger={null} collapsible collapsed={collapsed}>
          <div className="logo" />
          <Menu
            theme="light"
            mode="inline"
            defaultSelectedKeys={['1']}
            items={[
              {
                key: '1',
                icon: <UserOutlined />,
                label: '用户管理',
                onClick:() => {
                  navigate('/dashboard')
                }
              },
              {
                key: '2',
                icon: <OrderedListOutlined />,
                label: '订单管理',
                onClick:() => {
                  navigate('/dashboard/adminDesign')
                }
              },
              {
                key: '3',
                icon: <MoneyCollectOutlined />,
                label: '充值管理',
                onClick:() => {
                  navigate('/dashboard/adminOrder')
                }
              },
            ]}
          />
        </Sider>
        <Layout className="site-layout">
          <Header
            className="site-layout-background"
            style={{ paddingLeft: 30 }}
          >
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: 'trigger',
                onClick: () => setCollapsed(!collapsed),
              }
            )}
          </Header>
          <Content
            className="site-layout-background"
            style={{
              margin: '24px 16px',
              padding: 24,
              minHeight: '100vh',
            }}
          >
            <Outlet />
          </Content>
        </Layout>
      </Layout>
    </div>
  );
}

export default DashBoard;
