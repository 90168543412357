import { useEffect, useState } from 'react';
import { Table, Tag, Button, notification, Space, Form, Modal, Select, InputNumber } from 'antd';
import moment from 'moment';
import http from '@/utils/http';
import { useStateStore } from '@store';

import 'antd/es/table/style/css';
import 'antd/es/tag/style/css';
import 'antd/es/button/style/css';
import 'antd/es/notification/style/css';

const { Option } = Select;

function AdminDesign(params) {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useStateStore();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = (record) => {
    setIsModalVisible(true);
    form.setFieldsValue(record);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const refund = async (record) => {
    const resp = await http('post', '/pointRefund', { id: record._id });
    if (resp.status === 200) {
      notification.open({
        message: '',
        description: record.order_num + '退积分更改成功',
      });
      await fetchData();
    }
  };

  const approve = async (record) => {
    const resp = await http('post', '/approvePointOrder', { id: record._id });
    if (resp.status === 200) {
      notification.open({
        message: '',
        description: record.order_num + '审批成功',
      });
      await fetchData();
    }
  };

  // table 定义
  const columns = [
    {
      title: '订单状态',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => {
        let color = 'default';
        if (record.status === 'paid') {
          color = 'green';
        }
        if (record.status === 'refund') {
          color = 'volcano';
        }
        return <Tag color={color}>{record.status}</Tag>;
      },
    },
    {
      title: '订单号',
      dataIndex: 'order_num',
      key: 'order_num',
    },
    {
      title: '时间',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (_, record) =>
        moment(record.timestamp).format('YYYY-MM-DD hh:mm:ss'),
    },
    {
      title: '积分',
      dataIndex: 'cost_point',
      key: 'cost_point',
    },
    {
      title: '可用下载次数',
      dataIndex: 'downloadCount',
      key: 'downloadCount',
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => {
        return (
          <Space>
            <Button
              type="default"
              onClick={() => {
                showModal(record);
              }}
            >
              查看参数
            </Button>
            {
              record.status !== 'approve' && record.status !== 'refund' &&
              <Button
                type="primary"
                onClick={() => {
                  approve(record);
                }}
              >
                审批
              </Button>
            }
            {
              record.status !== 'refund' &&
              <Button
                type="primary"
                danger
                onClick={() => {
                  refund(record);
                }}
              >
                退积分
              </Button>
            }
          </Space>
        );
      },
    },
  ];

  const fetchData = async (user_id) => {
    setLoading(true);
    const resp = await http('get', '/getAllPointOrderList');
    setData(resp);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(user._id);
  }, [user._id]);

  return (
    <div>
      <Modal
        title="参数"
        visible={isModalVisible}
        onOk={handleCancel}
        onCancel={handleCancel}
        okText="确定"
        cancelText="取消"
      >
        <Form
          form={form}
          disabled
          name="basic"
          autoComplete="off"
        >
          {/* <div className=" text-lg pb-10">inductor</div> */}
          <div className=" text-lg pb-10">电感</div>
          {/* <Form.Item label="layer" name="inductor_layer"> */}
          <Form.Item label="金属层" name="inductor_layer">
            <Select>
              <Option value="AP">AP</Option>
              <Option value="M8">M8</Option>
              <Option value="M7">M7</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item label="number" name="inductor_number"> */}
          <Form.Item label="圈数" name="inductor_number">
            <Select>
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item label="radius" name="inductor_radius"> */}
          <Form.Item label="半径" name="inductor_radius">
            <InputNumber min={10} max={80} />
          </Form.Item>
          {/* <Form.Item label="width" name="inductor_width"> */}
          <Form.Item label="线宽" name="inductor_width">
            <InputNumber min={2} max={8} />
          </Form.Item>
          {/* <Form.Item label="strtech" name="inductor_strtech"> */}
          <Form.Item label="横向拉伸" name="inductor_strtech">
            <InputNumber min={0.2} max={0.5} precision={1} />
          </Form.Item>
          {/* <Form.Item label="clipping ratio" name="inductor_clipping_ratio"> */}
          <Form.Item label="剪切角" name="inductor_clipping_ratio">
            <InputNumber min={0.045} max={0.585} precision={3} />
          </Form.Item>
          {/* <Form.Item label="spacing" name="inductor_spacing"> */}
          <Form.Item label="线距" name="inductor_spacing">
            <InputNumber min={2} max={8} />
          </Form.Item>
          {/* <Form.Item label="freq" name="inductor_freq"> */}
          <Form.Item label="频率" name="inductor_freq">
            <InputNumber min={2} max={8} />
          </Form.Item>
          {/* <div className=" text-lg pb-10">Coaxial Transformer</div> */}
          <div className=" text-lg pb-10">同轴变压器</div>
          {/* <Form.Item label="layer" name="coaxxial_layer"> */}
          <Form.Item label="次级线圈金属层" name="coaxxial_layer">
            <Select>
              <Option value="AP">AP</Option>
              <Option value="M8">M8</Option>
              <Option value="M7">M7</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item label="type" name="coaxxial_type"> */}
          <Form.Item label="输入类型" name="coaxxial_type">
            <Select>
              <Option value="diff">diff</Option>
              <Option value="single">single</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item label="number_pri" name="coaxxial_number_pri"> */}
          <Form.Item label="主线圈圈数" name="coaxxial_number_pri">
            <Select>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item label="radius_pri" name="coaxxial_radius_pri"> */}
          <Form.Item label="主线圈半径" name="coaxxial_radius_pri">
            <InputNumber min={10} max={80} />
          </Form.Item>
          {/* <Form.Item label="width_pr" name="coaxxial_width_pri"> */}
          <Form.Item label="主线圈线宽" name="coaxxial_width_pri">
            <InputNumber min={2} max={8} />
          </Form.Item>
          {/* <Form.Item label="strtech" name="coaxxial_strtech"> */}
          <Form.Item label="横向拉伸" name="coaxxial_strtech">
            <InputNumber min={0.2} max={0.5} precision={1} />
          </Form.Item>
          {/* <Form.Item label="clipping ratio" name="coaxxial_clipping_ratio"> */}
          <Form.Item label="剪切角" name="coaxxial_clipping_ratio">
            <InputNumber min={0.045} max={0.585} precision={3} />
          </Form.Item>
          {/* <Form.Item label="spacing_pri" name="coaxxial_spacing_pri"> */}
          <Form.Item label="主线圈线距" name="coaxxial_spacing_pri">
            <InputNumber min={2} max={8} />
          </Form.Item>
          {/* <Form.Item label="freq" name="coaxxial_freq"> */}
          <Form.Item label="频率" name="coaxxial_freq">
            <InputNumber min={2} max={8} />
          </Form.Item>
          {/* <div className=" text-lg pb-10">Non-coaxial Transformer</div> */}
          <div className=" text-lg pb-10">非同轴变压器</div>
          {/* <Form.Item label="layer" name="non_coaxial_layer"> */}
          <Form.Item label="次级线圈金属层" name="non_coaxial_layer">
            <Select>
              <Option value="AP">AP</Option>
              <Option value="M8">M8</Option>
              <Option value="M7">M7</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item label="type" name="non_coaxial_type"> */}
          <Form.Item label="输入类型" name="non_coaxial_type">
            <Select>
              <Option value="diff">diff</Option>
              <Option value="single">single</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item label="number_pri" name="non_coaxial_number_pri"> */}
          <Form.Item label="主线圈圈数" name="non_coaxial_number_pri">
            <Select>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item label="radius_pri" name="non_coaxial_radius_pri"> */}
          <Form.Item label="主线圈半径" name="non_coaxial_radius_pri">
            <InputNumber min={10} max={80} />
          </Form.Item>
          {/* <Form.Item label="width_pr" name="non_coaxial_width_pri"> */}
          <Form.Item label="主线圈线宽" name="non_coaxial_width_pri">
            <InputNumber min={2} max={8} />
          </Form.Item>
          {/* <Form.Item label="spacing_pri" name="non_coaxial_spacing_pri"> */}
          <Form.Item label="主线圈线距" name="non_coaxial_spacing_pri">
            <InputNumber min={2} max={8} />
          </Form.Item>

          {/* <Form.Item label="number_sec" name="non_coaxial_number_sec"> */}
          <Form.Item label="次线圈圈数" name="non_coaxial_number_sec">
            <Select>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item label="radius_sec" name="non_coaxial_radius_sec"> */}
          <Form.Item label="次线圈半径" name="non_coaxial_radius_sec">
            <InputNumber min={10} max={80} />
          </Form.Item>
          {/* <Form.Item label="width_sec" name="non_coaxial_width_sec"> */}
          <Form.Item label="次线圈线宽" name="non_coaxial_width_sec">
            <InputNumber min={2} max={8} />
          </Form.Item>
          {/* <Form.Item label="spacing_sec" name="non_coaxial_spacing_sec"> */}
          <Form.Item label="次线圈线距" name="non_coaxial_spacing_sec">
            <InputNumber min={2} max={8} />
          </Form.Item>
          {/* <Form.Item label="center_distance" name="non_coaxial_center_distance"> */}
          <Form.Item label="主次线圈中心间距" name="non_coaxial_center_distance">
            <InputNumber min={2} max={8} />
          </Form.Item>

          {/* <Form.Item label="strtech" name="non_coaxial_stretch"> */}
          <Form.Item label="横向拉伸" name="non_coaxial_stretch">
            <InputNumber min={0.2} max={0.5} precision={1} />
          </Form.Item>
          {/* <Form.Item label="clipping ratio" name="non_coaxial_clipping_ratio"> */}
          <Form.Item label="剪切角" name="non_coaxial_clipping_ratio">
            <InputNumber min={0.045} max={0.585} precision={3} />
          </Form.Item>
          
          {/* <Form.Item label="freq" name="non_coaxial_freq"> */}
          <Form.Item label="频率" name="non_coaxial_freq">
            <InputNumber min={2} max={8} />
          </Form.Item>
        </Form>
      </Modal>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data}
        rowKey="_id"
      />
    </div>
  );
}

export default AdminDesign;
