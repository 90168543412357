import { Button, Form, Input, notification } from 'antd';

import http from '@/utils/http';
import { useStateStore } from '@store';

import 'antd/es/button/style/css';
import 'antd/es/form/style/css';
import 'antd/es/input/style/css';
import 'antd/es/notification/style/css';

function Password(params) {
  const { user } = useStateStore();

  const onFinish = async (values) => {
    if (values.password === values.password2) {
      const resp = await http('post', '/forget', { ...values, _id: user._id });
      if (resp.status === 200) {
        notification.open({
          message: '',
          description: '密码更改成功',
          onClick: () => {
            console.log('Notification Clicked!');
          },
        });
        console.log('Success:', values);
        return;
      }

      console.log('Fauile:', values);
    }
  };

  return (
    <div className="w-[1200px] m-auto flex items-center justify-center">
      <div className="w-[400px] m-auto border my-40 p-10 bg-gray-100 text-center">
        <h1 className="text-3xl font-normal pb-10">修改密码</h1>
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="密码"
            name="password"
            rules={[
              {
                required: true,
                message: '请输入密码!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="重复密码"
            name="password2"
            rules={[
              {
                required: true,
                message: '请输入密码!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              // offset: 8,
              // span: 16,
            }}
          >
            <Button size='large' type="primary" htmlType="submit">
              更改密码
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
export default Password;
