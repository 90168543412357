import { Link } from 'react-router-dom';

function Footer(params) {
  return (
    <div className="w-full">
      <div className="w-[1200px] m-auto py-6 flex">
        {/* 导航 */}
        <div className='pr-32'>
          <h1 className="text-2xl font-bold pb-4">导航</h1>
          <ul className="text-lg">
            <li className="py-1">
              <Link className="text-black" to="/">
                首页
              </Link>
            </li>
            <li className="py-1">
              <Link className="text-black" to="/">
                代设计服务
              </Link>
            </li>
            <li className="py-1">
              <Link className="text-black" to="/">
                联系方式
              </Link>
            </li>
          </ul>
        </div>
        {/* 用户 */}
        <div>
          <h1 className="text-2xl font-bold pb-4">用户</h1>
          <ul className="text-lg">
            <li className="py-1">
              <Link className="text-black" to="/">
                登录
              </Link>
            </li>
            <li className="py-1">
              <Link className="text-black" to="/">
                注册
              </Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="bg-gray-100 text-center py-6 text-base">
        All rights reserved
      </div>
    </div>
  );
}

export default Footer;
