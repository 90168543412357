import { Button, Form, Input, notification } from 'antd';

import http from '@/utils/http';
import { useDispatchStore, useStateStore } from '@store';

import 'antd/es/button/style/css';
import 'antd/es/form/style/css';
import 'antd/es/input/style/css';
import 'antd/es/notification/style/css';

function Charge(params) {
  const { user } = useStateStore();
  const dispatch = useDispatchStore();

  const onFinish = async (values) => {
    const resp = await http('post', '/createOrder', {
      order_num: Math.random() * 1e17,
      user_id: user._id,
      money: values.point,
      status: 'paid',
    });
    if (resp.status === 200) {
      await http('post', '/addPoint', {
        point: parseInt(values.point),
        _id: user._id,
      });
      notification.open({
        message: '',
        description: '充值金额成功',
        onClick: () => {
          console.log('Notification Clicked!');
        },
      });
      const userInfo = await http('get', '/getUser', {
        _id: user._id,
      });
      await dispatch({
        type: 'login',
        payload: userInfo.data,
      });
      console.log('Success:', values);
      return;
    }

    console.log('Fauile:', values);
  };

  return (
    <div className="w-[1200px] m-auto flex items-center justify-center">
      <div className="w-[400px] m-auto border my-40 p-10 bg-gray-100 text-center">
        <h1 className="text-3xl font-normal pb-10">充值</h1>
        <Form
          name="basic"
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="金额"
            name="point"
            rules={[
              {
                required: true,
                message: '请输入金额!',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              // offset: 8,
              // span: 16,
            }}
          >
            <Button size='large' type="primary" htmlType="submit">
              确定
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
export default Charge;
