import { Button, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';

import http from '@/utils/http';
import { useDispatchStore, useStateStore } from '@store';
import { setAuthToken, setLocal } from '@utils/tools'

// import 'antd/es/checkbox/style/css';
import 'antd/es/button/style/css';
import 'antd/es/form/style/css';
import 'antd/es/input/style/css';

function Login() {
  const dispatch = useDispatchStore();
  const { user } = useStateStore();
  const navigate = useNavigate();

  

  const onFinish = async (values) => {
    const resp = await http('post', '/login', values);
    if (resp.status === 200) {
      await dispatch({
        type: 'login',
        payload: resp.data,
      });
      setAuthToken(resp.data.token)
      setLocal('userId', resp.data._id)
      setLocal('authority', resp.data.authority)
      console.log('Success:', values, user);
      navigate('/user');
      return;
    }

    console.log('Fauile:', values);
  };

  return (
    <div className="w-[1200px] m-auto flex items-center justify-center py-32">
      <div className='bg-gray-100 p-10 text-center border'>
        <h1 className='text-3xl font-normal pb-10'>登录</h1>
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="手机号"
            name="phone"
            rules={[
              {
                required: true,
                message: '请输入手机号',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="密码"
            name="password"
            rules={[
              {
                required: true,
                message: '请输入密码!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            wrapperCol={{
              // offset: 8,
              // span: 16,
            }}
          >
            <Button size='large' type="primary" htmlType="submit">
              登录
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
export default Login;
