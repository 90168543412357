import { useEffect, useState } from 'react';
import { Table, Tag, Button } from 'antd';
import moment from 'moment';
import http from '@/utils/http';
import { useStateStore } from '@store';

import 'antd/es/table/style/css';
import 'antd/es/tag/style/css';
import 'antd/es/button/style/css';

function User() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { user } = useStateStore();

  // table 定义
  const columns = [
    {
      title: '订单状态',
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => {
        let color = 'default';
        if (record.status === 'paid') {
          color = 'green';
        }
        if (record.status === 'refund') {
          color = 'volcano';
        }
        return <Tag color={color}>{record.status}</Tag>;
      },
    },
    {
      title: '订单号',
      dataIndex: 'order_num',
      key: 'order_num',
    },
    {
      title: '时间',
      dataIndex: 'timestamp',
      key: 'timestamp',
      render: (_, record) =>
        moment(record.timestamp).format('YYYY-MM-DD hh:mm:ss'),
    },
    {
      title: '金额',
      dataIndex: 'money',
      key: 'money',
    },
    {
      title: '可用下载次数',
      dataIndex: 'downloadCount',
      key: 'downloadCount',
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => {
        if (record.downloadCount > 0) {
          return (
            <Button
              type="primary"
              onClick={() => {
                downFile(record);
              }}
            >
              下载文件
            </Button>
          );
        }
        return <Button disabled>下载次数已用完</Button>;
      },
    },
  ];

  const downFile = async (record) => {
    const resp = await http('get', '/getPointFileUrl', {id: record._id})
    await http('downFile', resp.data.fileUrl);
    fetchData(user._id);
  };

  const fetchData = async (user_id) => {
    setLoading(true);
    const resp = await http('get', '/getUserPointOrderList', { user_id });
    setData(resp);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(user._id);
  }, [user._id]);

  return (
    <div className="py-10 w-[1200px] m-auto">
      <div className="bg-gray-100 text-xl px-4 py-2">订单记录</div>
      <div className="text-center py-4">
        {data.length < 1 ? (
          '暂无订单记录'
        ) : (
          <Table
            loading={loading}
            columns={columns}
            dataSource={data}
            rowKey="_id"
          />
        )}
      </div>
    </div>
  );
}

export default User;
