import { useState, useEffect } from 'react';
import { Menu, Space, Button, Dropdown } from 'antd';
import { useDispatchStore, useStateStore } from '@store';
import { Link, useNavigate } from 'react-router-dom';
import { clearData, getLocal } from '@utils/tools';
import http from '@utils/http';

import 'antd/es/menu/style/css';
import 'antd/es/space/style/css';
import 'antd/es/button/style/css';
import 'antd/es/dropdown/style/css';

function Header() {
  const dispatch = useDispatchStore();

  // state
  const [current, setCurrent] = useState('home');
  const { user } = useStateStore();
  const navigate = useNavigate();

  const exit = () => {
    dispatch({
      type: 'init',
      payload: '',
    });
    clearData();
    navigate('/');
  };

  const menuClick = (e) => {
    setCurrent(e.key);
    navigate(`/${e.key}`);
  };

  const fetchUser = async (_id) => {
    const resp = await http('get', '/getUser', { _id });
    return resp;
  };

  useEffect(() => {
    const fetchData = async () => {
      const userId = getLocal('userId');
      const authority = getLocal('authority');
      if (userId && authority === 'user') {
        const result = await fetchUser(userId);
        dispatch({
          type: 'login',
          payload: result.data,
        });
      }
    };
    fetchData();
  }, [dispatch, navigate]);

  const items = [
    { label: '首页', key: 'home' }, // 菜单项务必填写 key
    { label: '代设计服务', key: 'design' },
    {
      label: '联系方法',
      key: 'contact',
    },
  ];
  const menu = (
    <Menu
      items={[
        {
          key: 'user',
          label: (
            <Button type="link">
              <Link to="/user">充值记录</Link>
            </Button>
          ),
        },
        // {
        //   key: 'order',
        //   label: (
        //     <Button type="link">
        //       <Link to="/order">充值记录</Link>
        //     </Button>
        //   ),
        // },
        {
          key: 'designOrder',
          label: (
            <Button type="link">
              <Link to="/designOrder">参数订单记录</Link>
            </Button>
          ),
        },
        {
          key: 'charge',
          label: (
            <Button type="link">
              <Link to="/charge">充值</Link>
            </Button>
          ),
        },
        {
          key: 'password',
          label: (
            <Button type="link">
              <Link to="/password">更改密码</Link>
            </Button>
          ),
        },
        {
          key: '4',
          label: (
            <Button type="link" onClick={exit}>
              退出
            </Button>
          ),
        },
      ]}
    />
  );

  return (
    <div className="w-full pt-4">
      <div className="w-[1200px] m-auto flex flex-row items-center">
        <h1 className="text-3xl mb-0 italic mr-28">synIntel</h1>
        <div className="flex justify-between w-full">
          <Menu
            className="border-0"
            onClick={menuClick}
            items={items}
            mode="horizontal"
            selectedKeys={[current]}
          ></Menu>
          <Space>
            {user.phone ? (
              <Space>
                <Dropdown overlay={menu} trigger={['click']}>
                  <Button type="link" onClick={(e) => e.preventDefault()}>
                    {user.phone}
                  </Button>
                </Dropdown>
                <span>{user.point} 积分</span>
              </Space>
            ) : (
              <>
                <Link to="/login">
                  <Button type="primary">登录</Button>
                </Link>
                <Link to="/register">
                  <Button>注册</Button>
                </Link>
              </>
            )}
          </Space>
        </div>
      </div>
    </div>
  );
}

export default Header;
