import React, { createContext, useReducer, useContext } from 'react';

const initialState = {
  user: {},
};

function reducer(state, action) {
  const { type, payload } = action;
  switch (type) {
    case 'init':
      return initialState;
    case 'login':
      return { ...state, user: payload };
    case 'setTotalBalance':
      return { ...state, totalBalance: payload };
    case 'setTotalStaked':
      return { ...state, totalStaked: payload };
    case 'setAvailableBalance':
      return { ...state, availableBalance: payload };
    case 'setRewards':
      return { ...state, rewards: payload };
    case 'setChain':
      return { ...state, chainInfo: payload };
    case 'setValidatorInfo':
      return { ...state, validatorInfo: payload };
    case 'setPrice':
      return { ...state, currentPrice: payload };
    default:
      throw new Error();
  }
}

const StateContext = createContext();
const DispatchContext = createContext();

function useStateStore() {
  return useContext(StateContext);
}
function useDispatchStore() {
  return useContext(DispatchContext);
}

function StoreProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </StateContext.Provider>
  );
}

export { useStateStore, useDispatchStore, StoreProvider };
