import { Navigate, useRoutes } from 'react-router-dom';
import Index from './page/index';
import Home from './page/home';
import Dashboard from './page/dashboard';
import Contact from './page/contact';
import Login from './page/login';
import Register from './page/register';
import User from './page/user';
import Password from './page/password';
import Charge from './page/charge';
import Design from './page/design';
import Order from './page/order';
import DesignOrder from './page/designOrder';
import AdminUser from './page/adminUser';
import AdminOrder from './page/adminOrder';
import AdminDesign from './page/adminDesign';
import AdminLogin from './page/adminLogin';
import { getLocal } from '@utils/tools';

const routes = [
  {
    path: '/',
    element: <Index />,
    children: [
      { path: '/', element: <Home />, index: true },
      { path: '/home', element: <Home />, index: true },
      { path: '/contact', element: <Contact /> },
      { path: '/login', element: <Login /> },
      {
        path: '/register',
        element: <Register />,
      },
      {
        path: '/user',
        element: <User />,
        meta: {
          authority: 'user',
        },
      },
      {
        path: '/order',
        element: <Order />,
        meta: {
          authority: 'user',
        },
      },
      {
        path: '/designOrder',
        element: <DesignOrder />,
        meta: {
          authority: 'user',
        },
      },
      {
        path: '/password',
        element: <Password />,
        meta: {
          authority: 'user',
        },
      },
      {
        path: '/charge',
        element: <Charge />,
        meta: {
          authority: 'user',
        },
      },
      {
        path: '/design',
        element: <Design />,
      },
    ],
  },
  { path: '/adminLogin', element: <AdminLogin /> },
  {
    path: '/dashboard',
    element: <Dashboard />,
    children: [
      {
        path: '/dashboard',
        element: <AdminUser />,
        index: true,
        meta: {
          authority: 'admin',
        },
      },
      {
        path: '/dashboard/adminUser',
        element: <AdminUser />,
        index: true,
        meta: {
          authority: 'admin',
        },
      },
      {
        path: '/dashboard/adminDesign',
        element: <AdminDesign />,
        meta: {
          authority: 'admin',
        },
      },
      {
        path: '/dashboard/adminOrder',
        element: <AdminOrder />,
        meta: {
          authority: 'admin',
        },
      },
    ],
  },
  // { path: '/home/:postId', element: <Home /> },
  { path: '*', element: <div>404</div> },
];

// 路由拦截
const RouterBeforeEach = (props) => {
  const token = getLocal('token');
  const userId = getLocal('userId');
  const authority = getLocal('authority');
  const routerKey = window.location.pathname;
  console.log("🚀 ~ file: routes.js:121 ~ RouterBeforeEach ~ props?.route?.meta?.authority", props?.route?.meta?.authority)
  if (props?.route?.meta?.authority === 'user') {
    if ((!token || !userId || authority !== 'user') && !['/login'].includes(routerKey)) {
      return <Navigate to={'/login'} replace />;
    }
  }
  if (props?.route?.meta?.authority === 'admin') {
    if ((!token || !userId || authority !== 'admin') && !['/adminLogin'].includes(routerKey)) {
      return <Navigate to={'/adminLogin'} replace />;
    }
  }
  return <div>{props.children}</div>;
};
// 渲染路由
const renderRoutes = (routes) => {
  console.log('🚀 ~ file: routes.js:46 ~ renderRoutes ~ routes', routes);
  return routes.map((item) => {
    const route = { meta: item.meta, path: item.path };
    if (item.element) {
      route.element = (
        <RouterBeforeEach route={item}>{item.element}</RouterBeforeEach>
      );
      // route.element = item.element
    }
    if (item.children) {
      route.children = renderRoutes(item.children);
    }
    if (item.redirect) {
      route.element = <Navigate to={item.redirect} />;
    }
    return route;
  });
};
const Routes = () => {
  const routesResult = useRoutes(renderRoutes(routes));
  return routesResult;
};

export default Routes;
