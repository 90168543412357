import { Button, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';

import http from '@/utils/http';
import { useDispatchStore, useStateStore } from '@store';
import { setAuthToken, setLocal } from '@utils/tools'

// import 'antd/es/checkbox/style/css';
import 'antd/es/button/style/css';
import 'antd/es/form/style/css';
import 'antd/es/input/style/css';

function Register() {
  const dispatch = useDispatchStore();
  const { user } = useStateStore();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    if (values.password === values.password2) {
      const resp = await http('post', '/register', values);
      if (resp.status === 200) {
        const loginResp = await http('post', '/login', {
          phone: values.phone,
          password: values.password,
        });
        await dispatch({
          type: 'login',
          payload: loginResp.data,
        });
        setAuthToken(loginResp.data.token);
        setLocal('userId', loginResp.data._id);
        setLocal('authority', resp.data.authority)
        console.log('Success:', values, user);
        navigate('/user');
        return;
      }

      console.log('Fauile:', values);
    }
  };

  return (
    <div className="w-[1200px] m-auto flex items-center justify-center py-32">
      <div className="bg-gray-100 p-10 text-center border">
        <h1 className="text-3xl font-normal pb-10">注册</h1>
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          <Form.Item
            label="手机号"
            name="phone"
            rules={[
              {
                required: true,
                message: '请输入手机号',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="邮箱"
            name="email"
            rules={[
              {
                required: true,
                message: '请输入邮箱',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="密码"
            name="password"
            rules={[
              {
                required: true,
                message: '请输入密码!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="重复密码"
            name="password2"
            rules={[
              {
                required: true,
                message: '请输入密码!',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            wrapperCol={
              {
                // offset: 8,
                // span: 16,
              }
            }
          >
            <Button size="large" type="primary" htmlType="submit">
              注册
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
export default Register;
