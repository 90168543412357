import { Button, Form, Radio, notification, Select, InputNumber } from 'antd';
import { useNavigate } from 'react-router-dom';
// import { WechatOutlined, AlipayCircleOutlined } from '@ant-design/icons'

import http from '@/utils/http';
import { useStateStore } from '@store';

import 'antd/es/button/style/css';
import 'antd/es/form/style/css';
// import 'antd/es/input/style/css';
import 'antd/es/radio/style/css';
// import 'antd/es/inputnumber/style/css';
import 'antd/es/notification/style/css';
import 'antd/es/select/style/css';

const { Option } = Select;

function Design(params) {
  const { user } = useStateStore();
  const navigate = useNavigate();

  const onFinish = async (values) => {
    if (!user._id) {
      navigate('/login');
      return;
    }
    const resp = await http('post', '/createPointOrder', {
      ...values,
      user_id: user._id,
      status: 'paid',
      cost_point: 100,
    });
    if (resp.status === 200) {
      notification.open({
        message: '',
        description: '订单生成成功',
      });
      console.log('Success:', values);
      return;
    }

    console.log('Fauile:', values);
  };

  return (
    <div className="w-[1200px] m-auto">
      <div className="w-[700px] m-auto border my-40 p-10">
        <div className=" text-lg pb-10 font-bold">器件生成</div>
        <Form
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          autoComplete="off"
        >
          {/* <div className=" text-lg pb-10">inductor</div> */}
          <div className=" text-lg pb-10">电感</div>
          {/* <Form.Item label="layer" name="inductor_layer"> */}
          <Form.Item label="金属层" name="inductor_layer">
            <Select>
              <Option value="AP">AP</Option>
              <Option value="M8">M8</Option>
              <Option value="M7">M7</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item label="number" name="inductor_number"> */}
          <Form.Item label="圈数" name="inductor_number">
            <Select>
              <Option value="1">1</Option>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item label="radius" name="inductor_radius"> */}
          <Form.Item label="半径" name="inductor_radius">
            <InputNumber min={10} max={80} />
          </Form.Item>
          {/* <Form.Item label="width" name="inductor_width"> */}
          <Form.Item label="线宽" name="inductor_width">
            <InputNumber min={2} max={8} />
          </Form.Item>
          {/* <Form.Item label="strtech" name="inductor_strtech"> */}
          <Form.Item label="横向拉伸" name="inductor_strtech">
            <InputNumber min={0.2} max={0.5} precision={1} />
          </Form.Item>
          {/* <Form.Item label="clipping ratio" name="inductor_clipping_ratio"> */}
          <Form.Item label="剪切角" name="inductor_clipping_ratio">
            <InputNumber min={0.045} max={0.585} precision={3} />
          </Form.Item>
          {/* <Form.Item label="spacing" name="inductor_spacing"> */}
          <Form.Item label="线距" name="inductor_spacing">
            <InputNumber min={2} max={8} />
          </Form.Item>
          {/* <Form.Item label="freq" name="inductor_freq"> */}
          <Form.Item label="频率" name="inductor_freq">
            <InputNumber min={2} max={8} />
          </Form.Item>
          {/* <div className=" text-lg pb-10">Coaxial Transformer</div> */}
          <div className=" text-lg pb-10">同轴变压器</div>
          {/* <Form.Item label="layer" name="coaxxial_layer"> */}
          <Form.Item label="次级线圈金属层" name="coaxxial_layer">
            <Select>
              <Option value="AP">AP</Option>
              <Option value="M8">M8</Option>
              <Option value="M7">M7</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item label="type" name="coaxxial_type"> */}
          <Form.Item label="输入类型" name="coaxxial_type">
            <Select>
              <Option value="diff">diff</Option>
              <Option value="single">single</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item label="number_pri" name="coaxxial_number_pri"> */}
          <Form.Item label="主线圈圈数" name="coaxxial_number_pri">
            <Select>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item label="radius_pri" name="coaxxial_radius_pri"> */}
          <Form.Item label="主线圈半径" name="coaxxial_radius_pri">
            <InputNumber min={10} max={80} />
          </Form.Item>
          {/* <Form.Item label="width_pr" name="coaxxial_width_pri"> */}
          <Form.Item label="主线圈线宽" name="coaxxial_width_pri">
            <InputNumber min={2} max={8} />
          </Form.Item>
          {/* <Form.Item label="strtech" name="coaxxial_strtech"> */}
          <Form.Item label="横向拉伸" name="coaxxial_strtech">
            <InputNumber min={0.2} max={0.5} precision={1} />
          </Form.Item>
          {/* <Form.Item label="clipping ratio" name="coaxxial_clipping_ratio"> */}
          <Form.Item label="剪切角" name="coaxxial_clipping_ratio">
            <InputNumber min={0.045} max={0.585} precision={3} />
          </Form.Item>
          {/* <Form.Item label="spacing_pri" name="coaxxial_spacing_pri"> */}
          <Form.Item label="主线圈线距" name="coaxxial_spacing_pri">
            <InputNumber min={2} max={8} />
          </Form.Item>
          {/* <Form.Item label="freq" name="coaxxial_freq"> */}
          <Form.Item label="频率" name="coaxxial_freq">
            <InputNumber min={2} max={8} />
          </Form.Item>
          {/* <div className=" text-lg pb-10">Non-coaxial Transformer</div> */}
          <div className=" text-lg pb-10">非同轴变压器</div>
          {/* <Form.Item label="layer" name="non_coaxial_layer"> */}
          <Form.Item label="次级线圈金属层" name="non_coaxial_layer">
            <Select>
              <Option value="AP">AP</Option>
              <Option value="M8">M8</Option>
              <Option value="M7">M7</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item label="type" name="non_coaxial_type"> */}
          <Form.Item label="输入类型" name="non_coaxial_type">
            <Select>
              <Option value="diff">diff</Option>
              <Option value="single">single</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item label="number_pri" name="non_coaxial_number_pri"> */}
          <Form.Item label="主线圈圈数" name="non_coaxial_number_pri">
            <Select>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item label="radius_pri" name="non_coaxial_radius_pri"> */}
          <Form.Item label="主线圈半径" name="non_coaxial_radius_pri">
            <InputNumber min={10} max={80} />
          </Form.Item>
          {/* <Form.Item label="width_pr" name="non_coaxial_width_pri"> */}
          <Form.Item label="主线圈线宽" name="non_coaxial_width_pri">
            <InputNumber min={2} max={8} />
          </Form.Item>
          {/* <Form.Item label="spacing_pri" name="non_coaxial_spacing_pri"> */}
          <Form.Item label="主线圈线距" name="non_coaxial_spacing_pri">
            <InputNumber min={2} max={8} />
          </Form.Item>

          {/* <Form.Item label="number_sec" name="non_coaxial_number_sec"> */}
          <Form.Item label="次线圈圈数" name="non_coaxial_number_sec">
            <Select>
              <Option value="2">2</Option>
              <Option value="3">3</Option>
            </Select>
          </Form.Item>
          {/* <Form.Item label="radius_sec" name="non_coaxial_radius_sec"> */}
          <Form.Item label="次线圈半径" name="non_coaxial_radius_sec">
            <InputNumber min={10} max={80} />
          </Form.Item>
          {/* <Form.Item label="width_sec" name="non_coaxial_width_sec"> */}
          <Form.Item label="次线圈线宽" name="non_coaxial_width_sec">
            <InputNumber min={2} max={8} />
          </Form.Item>
          {/* <Form.Item label="spacing_sec" name="non_coaxial_spacing_sec"> */}
          <Form.Item label="次线圈线距" name="non_coaxial_spacing_sec">
            <InputNumber min={2} max={8} />
          </Form.Item>
          {/* <Form.Item label="center_distance" name="non_coaxial_center_distance"> */}
          <Form.Item
            label="主次线圈中心间距"
            name="non_coaxial_center_distance"
          >
            <InputNumber min={2} max={8} />
          </Form.Item>

          {/* <Form.Item label="strtech" name="non_coaxial_stretch"> */}
          <Form.Item label="横向拉伸" name="non_coaxial_stretch">
            <InputNumber min={0.2} max={0.5} precision={1} />
          </Form.Item>
          {/* <Form.Item label="clipping ratio" name="non_coaxial_clipping_ratio"> */}
          <Form.Item label="剪切角" name="non_coaxial_clipping_ratio">
            <InputNumber min={0.045} max={0.585} precision={3} />
          </Form.Item>

          {/* <Form.Item label="freq" name="non_coaxial_freq"> */}
          <Form.Item label="频率" name="non_coaxial_freq">
            <InputNumber min={2} max={8} />
          </Form.Item>

          <Form.Item label="价格" name="money">
            <span>100元 或 100积分</span>
          </Form.Item>

          <Form.Item label="请选择支付方式" name="paidType">
            <Radio.Group defaultValue={'point'}>
              {/* <Radio value="alipay">支付宝</Radio>
              <Radio value="wepay">微信</Radio> */}
              <Radio value="point">积分</Radio>
            </Radio.Group>
          </Form.Item>

          <div className="text-center">
            <Button type="primary" htmlType="submit">
              提交
            </Button>
          </div>
        </Form>
      </div>
    </div>
    // <div
    //   className="w-[1200px] m-auto py-10"
    //   style={{ height: 'calc( 100vh - 400px )' }}
    // >
    //   <div className="w-full bg-gray-100 py-2 px-4">电感</div>
    //   <div className='py-4'>
    //     <Form
    //       name="basic"
    //       labelCol={{
    //         span: 8,
    //       }}
    //       wrapperCol={{
    //         span: 8,
    //       }}
    //       initialValues={{
    //         remember: true,
    //       }}
    //       onFinish={onFinish}
    //       autoComplete="off"
    //     >
    //       <Form.Item
    //         label="金属层"
    //         name="layer"
    //         rules={[
    //           {
    //             required: true,
    //           },
    //         ]}
    //       >
    //         <Select>
    //           <Option value="AP">AP</Option>
    //           <Option value="M8">M8</Option>
    //           <Option value="M7">M7</Option>
    //         </Select>
    //       </Form.Item>
    //       <Form.Item
    //         label="圈数"
    //         name="number"
    //         rules={[
    //           {
    //             required: true,
    //           },
    //         ]}
    //       >
    //         <Select>
    //           <Option value="1">1</Option>
    //           <Option value="2">2</Option>
    //           <Option value="3">3</Option>
    //         </Select>
    //       </Form.Item>
    //       <Form.Item
    //         label="半径"
    //         name="radius"
    //         rules={[
    //           {
    //             required: true,
    //           },
    //         ]}
    //       >
    //         <InputNumber min={10} max={80} />
    //       </Form.Item>
    //       <Form.Item
    //         label="线宽"
    //         name="width"
    //         rules={[
    //           {
    //             required: true,
    //           },
    //         ]}
    //       >
    //         <InputNumber min={2} max={8} />
    //       </Form.Item>
    //       <Form.Item
    //         label="横向拉伸"
    //         name="width"
    //         rules={[
    //           {
    //             required: true,
    //           },
    //         ]}
    //       >
    //         <InputNumber min={0.2} max={0.5} />
    //       </Form.Item>
    //       <Form.Item
    //         label="剪切角"
    //         name="clipping_ratio"
    //         rules={[
    //           {
    //             required: true,
    //           },
    //         ]}
    //       >
    //         <InputNumber min={0.045} max={0.585} />
    //       </Form.Item>
    //       <Form.Item
    //         label="线距"
    //         name="clipping_ratio"
    //         rules={[
    //           {
    //             required: true,
    //           },
    //         ]}
    //       >
    //         <InputNumber min={0.045} max={0.585} />
    //       </Form.Item>

    //     </Form>
    //   </div>
    // </div>
  );
}

export default Design;
