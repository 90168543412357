import { useState, useEffect } from 'react';
import { Table, Button, Space, notification, Modal, Form, InputNumber } from 'antd';

import http from '@/utils/http';

import 'antd/es/table/style/css';
import 'antd/es/space/style/css';
import 'antd/es/button/style/css';
import 'antd/es/modal/style/css';
import 'antd/es/form/style/css';
import 'antd/es/input/style/css';
import 'antd/es/notification/style/css';
import'antd/es/input-number/style/css'

function AdminUser(params) {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [passwordUser, setPasswordUser] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const resetPassword = async (record) => {
    const resp = await http('post', '/forget', {
      password: '123456',
      _id: record._id,
    });
    if (resp.status === 200) {
      notification.open({
        message: '',
        description: record.phone + '密码更改成功',
      });
      return;
    }
  };

  const showModal = (record) => {
    setIsModalVisible(true);
    setPasswordUser(record);
    form.setFieldValue('point', record.point)
  };

  const handleOk = async () => {
    const point = form.getFieldValue('point')
    const resp =  await http('post', '/editPoint', {
      point: parseInt(point),
      _id: passwordUser._id,
    });
    if (resp.status === 200) {
      setIsModalVisible(false);
      setPasswordUser()
      form.resetFields()
      notification.open({
        message: '',
        description: passwordUser.phone + '积分更改成功',
      });
      await fetchData()
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const fetchData = async () => {
    setLoading(true);
    const resp = await http('get', '/getAllUser');
    setData(resp.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const columns = [
    {
      title: '用户名',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: '积分',
      dataIndex: 'point',
      key: 'point',
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => {
        return (
          <Space>
            <Button
              type="primary"
              onClick={() => {
                showModal(record);
              }}
            >
              编辑积分
            </Button>
            <Button
              type="primary"
              onClick={() => {
                resetPassword(record);
              }}
            >
              重置密码
            </Button>
          </Space>
        );
      },
    },
  ];


  return (
    <div>
      <Modal
        title="编辑积分"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="确定"
        cancelText="取消"
      >
        <Form form={form} name="control-hooks">
          <Form.Item name="point" label="积分" rules={[{ required: true }]}>
            <InputNumber min={0} />
          </Form.Item>
        </Form>
      </Modal>
      <Table
        loading={loading}
        columns={columns}
        dataSource={data}
        rowKey="_id"
      />
    </div>
  );
}

export default AdminUser;
