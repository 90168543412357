export const setAuthToken = (token) => {
  localStorage.setItem('token', token)
}

export const clearData = () => {
  localStorage.clear();
}

export const setLocal = (key, val) => {
  localStorage.setItem(key, val)
}

export const getLocal = (key) => {
  return localStorage.getItem(key)
}