import { MailFilled, PhoneFilled } from '@ant-design/icons';

function Contact(params) {
  return (
    <section
      className="my-40 w-[1200px] m-auto"
    >
      <h1 className="text-4xl font-bold">联系我们</h1>
      <div className="pt-32 pl-80 text-3xl text-gray-400 flex items-center m-auto">
        <h1 className="text-5xl pr-20">
          <MailFilled />
        </h1>
        <h1>jiangyanhao1991@gmail.com</h1>
      </div>
      <div className="pt-10 pb-32 pl-80 text-3xl text-gray-400 flex items-center m-auto">
        <h1 className="text-5xl pr-20">
          <PhoneFilled />
        </h1>
        <h1>15989241210</h1>
      </div>
    </section>
  );
}

export default Contact;
