import { useNavigate } from 'react-router-dom';
// import MyCarousel from '@components/MyCarousel';

function Home(params) {
  const navigate = useNavigate();
  const gotoDesign = () => {
    navigate('/design')
  }

  return (
    <>
      {/* <MyCarousel>
        <div className="relative">
          <img
            className="w-full h-[800px]"
            src={`${process.env.PUBLIC_URL}/banner_1.jpg`}
            alt=""
          />
          <div className="absolute bottom-20 left-20">
            <h1 className="text-white font-bold text-4xl">
              射频器件和电路设计综合服务
            </h1>
            <h1 className="text-white font-bold text-4xl">
              让高端复杂的射频芯片设计走向大众，为万物智联的世界更近一步。
            </h1>
          </div>
        </div>
        <div className="relative">
          <img
            className="w-full h-[800px]"
            src={`${process.env.PUBLIC_URL}/banner_2.jpg`}
            alt=""
          />
          <div className="absolute bottom-20 left-20">
            <h1 className="text-white font-bold text-4xl">
              射频器件和电路设计综合服务
            </h1>
            <h1 className="text-white font-bold text-4xl">
              让高端复杂的射频芯片设计走向大众，为万物智联的世界更近一步。
            </h1>
          </div>
        </div>
      </MyCarousel> */}
      <div className="w-[1400px] m-auto relative">
          <img
            className="w-full h-[800px]"
            src={`${process.env.PUBLIC_URL}/banner_3.jpg`}
            alt=""
          />
          <div className="absolute bottom-20 left-20">
            <h1 className="text-white font-bold text-4xl">
              射频器件和电路设计综合服务
            </h1>
            <h1 className="text-white font-bold text-4xl">
              让高端复杂的射频芯片设计走向大众，为万物智联的世界更近一步。
            </h1>
          </div>
        </div>
      {/* content */}
      <section className="w-full">
        <div className="bg-gray-100">
          <div className="w-[1200px] py-4 m-auto">
            <h1 className="font-bold text-2xl mb-0">无源模块代设计服务</h1>
          </div>
        </div>
        <div className="w-[1200px] m-auto py-10">
          <p className="text-xl" style={{ textIndent: 40 }}>
            射频芯片设计通常基于一系列射频IP电路如功率放大器（PA），低噪声放大器（LNA）以及开关（Switch）等。而这些射频IP电路通常包含有源电路及大量无源部分如电感和变压器等。其中无源部分通常被用来作为单端和差分信号转化电路，匹配网络电路等，在射频设计中起到非常关键的作用。而目前代工厂所提供的无源部分如电感和变压器模型种类和频率范围有限，常常难以满足实际设计的需求。为了解决这个问题，通常IC设计工程师需要采用电磁仿真（EM
            simulation），不断调整这些无源部分的版图，反复仿真，直到电路达到设计指标为止，这个过程虽然设计创新性不大，但是非常耗时费力。我们采用人工智能方法，能够针对设计需求，迅速找到最优的无源模块版图几何参数。根据客户提供的设计电学，我们可以提供设计版图，几何参数以及电磁仿真验证报告，免去您反复迭代费力设计的烦恼。
          </p>
          <img
            src={`${process.env.PUBLIC_URL}/p_1.jpg`}
            className="w-full py-4"
            alt=""
          />
          <div className="flex justify-between items-center">
            <div className="w-1/2">
              <div className="h-[400px]">
                <img
                  src={`${process.env.PUBLIC_URL}/p_2.jpg`}
                  className="h-[400px] py-4 pr-5"
                  alt=""
                />
              </div>
              <p className="text-center">（PA的版图/示意图）</p>
            </div>
            <div className="w-1/2 text-center">
              <div className="h-[400px] flex items-center">
                <img
                  src={`${process.env.PUBLIC_URL}/p_3.jpg`}
                  className="py-4 pl-5 m-auto"
                  alt=""
                />
              </div>
              <p className="text-center">（PA加偏置，不包含匹配模块）</p>
            </div>
          </div>
        </div>
      </section>
      {/* 客户需求 */}
      <section className="w-full">
        <div className="bg-gray-100">
          <div className="w-[1200px] py-4 m-auto">
            <h1 className="font-bold text-2xl mb-0">实例1</h1>
          </div>
        </div>
        <div className="w-[1200px] flex m-auto py-5">
          <h1 className="text-2xl w-[180px]">客户需求：</h1>
          <p className="text-xl mb-0" style={{ textIndent: 40 }}>
            在40nm工艺节点里，我需要设计一个工作在77GHz的电感，达到Q=19；L=150pH;
            SRF{`>`}154GHz(即两倍工作频率)这些指标，所有指标要求误差在±5%以内。
          </p>
        </div>
      </section>
      {/* 提供 */}
      <section className="w-full">
        <div className="bg-gray-100">
          <div className="w-[1200px] py-4 m-auto">
            <h1 className="font-bold text-2xl mb-0">synIntel提供：</h1>
          </div>
        </div>
        <div className="w-[1200px] m-auto flex py-8">
          <div className="w-1/2 pr-4">
            <img
              src={`${process.env.PUBLIC_URL}/p_4.jpg`}
              className="w-full"
              alt=""
            />
          </div>
          <div className="w-1/2 pl-4">
            <p className="text-lg">
              (1) 电感 GDSII 文件，可以直接用 Cadence
              Virtuoso打开，插入已有设计或者直接流片
            </p>
            <p className="text-lg">(2) 信息文档包括：</p>
            <div className="bg-gray-200 p-4 h-[400px] flex flex-col justify-around">
              <div>
                <p className="text-lg">最优电感几何尺寸表格：</p>
                <img src={`${process.env.PUBLIC_URL}/t_1.png`} alt="" />
              </div>
              <div>
                <p className="text-lg">电磁仿真验证报告（Excel表格）：</p>
                <img src={`${process.env.PUBLIC_URL}/t_2.png`} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* button */}
      <section className="w-full py-20">
        <div onClick={gotoDesign} className="w-[400px] h-[100px] m-auto text-4xl font-bold text-white bg-blue-400 text-center leading-[100px] rounded-lg cursor-pointer">
          获取代设计服务
        </div>
      </section>
    </>
  );
}

export default Home;
